@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .moje-card {
    @apply rounded-lg p-5;
    @apply bg-white;
    @apply border-gray-200 border border-solid;
    @apply break-inside-avoid-column;
  }
  .moje-card-title {
    @apply text-2xl font-bold dark:text-white;
    /* font-brand-grey-800  */
  }
  .moje-table {
    @apply table-auto border-collapse;
  }
  .moje-table tr {
    border-bottom: 1px solid #eee;
  }

  .moje-table th {
    @apply border-b border-slate-400 dark:border-slate-600 font-medium p-4 text-left;
  }
  .moje-table thead {
    @apply font-semibold text-lg;
  }
  .moje-table thead tr {
    @apply font-semibold text-lg;
  }
}
*,
::before,
::after {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

:root {
  --tw-bg-opacity: 1;
}
